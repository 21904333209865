import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
// import ReadMoreReact from 'read-more-react';
import ShowMore from 'react-show-more';
import xss from 'xss';

import scrollTo from '../helpers/scrollTo';

import Bars1Src from '../img/svg/bars-1.svg';
import Bars2Src from '../img/svg/bars-2.svg';
import RecrutTopSrc from '../img/svg/pageCareers/recruitment_top.svg';
import RecrutBottomSrc from '../img/svg/pageCareers/recruitment_bottom.svg';
import ProcessSrc from '../img/svg/pageCareers/process.png';
import ProcessMobileSrc from '../img/svg/pageCareers/process-mobile.png';

import Layout from '../components/Layout';
import Button from '../components/Button';
import IconedText from '../components/IconedText';
import Card from '../components/Card';

const optionsXSS = {
  whiteList: {
    a: ["href", "title", "target"],
  },
};
const myxss = new xss.FilterXSS(optionsXSS);

export const CareersPageTemplate = ({
  // recruitmentUrl,
  hero,
  photo,
  textPhoto,
  ctaNewsletter,
  perks,
  process,
  cta,
  // positions
}) => (
  <div className="Careers">
    <section className="Careers__hero">
      <div className="Careers__hero__container">
        <h1>{hero.title}</h1>

        <ReactMarkdown children={hero.text} rehypePlugins={[rehypeRaw]} />

        <div className="Careers__cta">
          <Button to="/jobs">
            {hero.ctaLabel}
          </Button>
        </div>
      </div>
    </section>

    <section className="Careers__photo">
      <div className="Careers__background"></div>
      <div className="Careers__container">
        <SVG
          src={Bars1Src}
          className="Careers__photo-bar Careers__photo-bar--1"
        />

        <SVG
          src={Bars2Src}
          className="Careers__photo-bar Careers__photo-bar--2"
        />

        <GatsbyImage image={getImage(photo.image)} alt={photo.alt} className="Careers__photo-main" />
      </div>
    </section>

    <section className="Careers__section Careers__section--pB0">
      <div className="Careers__background"></div>
      <div className="Careers__container-inner">
        <div className="Careers__container-text Careers__container-text--1">
          <ReactMarkdown children={textPhoto} rehypePlugins={[rehypeRaw]} />
        </div>
      </div>
    </section>

    <section className="bg-secondary py-14 relative">
      <div className="container mx-auto relative z-10">
        <div className="text-center leading-relaxed max-w-xs xs:max-w-none mx-auto">
          {/* <p className="mb-2 text-white sm:text-2xl">
            xxx
          </p> */}
          <h2 className="text-white text-lg sm:text-3xl font-bold mb-9">
            {ctaNewsletter.title}
          </h2>
          <div className="text-center">
            <div onClick={() => scrollTo('footer')} className="cursor-pointer w-full xxs:w-auto inline-block bg-primary hover:bg-primary-darken active:bg-primary-lighten rounded uppercase font-bold py-4 px-4 xxs:px-12 text-white text-xs sm:text-base">
              {ctaNewsletter.btnText}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="Careers__section Careers__section--2">
      <div className="Careers__background"></div>
      <div className="Careers__container">
        <h2 className="Careers__subtitle">{perks.title}</h2>

        <div className="Careers__container-text Careers__container-text--2">
          <ReactMarkdown children={perks.text} rehypePlugins={[rehypeRaw]} />
        </div>

        <ul className="Careers__perks Careers__perks--selected">
          {perks.items.map((item, i) => {
            if (item.wideSize) {
              return (
                <li className="Careers__perk Careers__perk--wide">
                  <IconedText
                    key={i}
                    tagName="div"
                    className="Careers__perk__item"
                    title={item.name}
                    iconSrc={item.icon.publicURL || item.icon}
                    variants={item.wideSize ? [] : ['center']}
                  >
                    <ShowMore
                      lines={3}
                      more='Show more'
                      less='Show less'
                    >
                      <div dangerouslySetInnerHTML={{ __html: myxss.process(item.text) }} />
                    </ShowMore>
                  </IconedText>
                </li>
              )
            }
          })}
        </ul>

        <ul className="Careers__perks">
          {perks.items.map((item, i) => {
            if (!item.wideSize) {
              return (
                <li className="Careers__perk">
                  <IconedText
                    key={i}
                    tagName="div"
                    className="Careers__perk__item"
                    title={item.name}
                    iconSrc={item.icon.publicURL || item.icon}
                    variants={item.wideSize ? [] : ['center']}
                  >
                    <ShowMore
                      lines={2}
                      more='Show more'
                      less='Show less'
                    >
                      <div dangerouslySetInnerHTML={{ __html: myxss.process(item.text) }} />
                    </ShowMore>
                  </IconedText>
                </li>
              )
            }
          })}
        </ul>
      </div>
    </section>

    <section className="Careers__process">
      <div className="Careers__under">
        <div className="Careers__under__background">
          <SVG
            src={RecrutTopSrc}
            uniquifyIDs={false}
            className="Careers__under__background__img"
          />
        </div>
      </div>
      <div className="Careers__container">
        <div className="Careers__process__container">
          <h2 className="Careers__process__title">
            {process.title}
          </h2>
          <h3 className="Careers__process__subtitle">
            {process.subtitle}
          </h3>
        </div>

        <img
          src={ProcessSrc}
          className="Careers__process__img Careers__process__img--desktop"
        />
        <img
          src={ProcessMobileSrc}
          className="Careers__process__img Careers__process__img--mobile"
        />
        <div className="Careers__process__container">
          <p className="Careers__process__text">
            {process.text}
          </p>
        </div>
      </div>
      <div className="Careers__under">
        <div className="Careers__under__background">
          <SVG
            src={RecrutBottomSrc}
            uniquifyIDs={false}
            className="Careers__under__background__img"
          />
        </div>
      </div>
    </section>

    <section className="Careers__section pt-8 sm:pt-28">
      <div className="Careers__background"></div>
      <div className="Careers__container">
        <h2 className="text-2xl sm:text-3xl mb-8 sm:mb-12 font-bold text-secondary text-center">
          {cta.title}
        </h2>
        <div className="text-center">
          <Button to="/jobs">
            {cta.btnText}
          </Button>
        </div>
      </div>
    </section>

    {/* <section className="Careers__section Careers__section--2" id="sectionCareersPositions">
      <div className="Careers__background"></div>
      <div className="Careers__container">
        <h2 className="Careers__subtitle">{positions.title}</h2>

        <ul className={classNames('Careers__positions', {
          'Careers__positions--four': positions.items && positions.items.length === 4,
          'Careers__positions--oneThird': positions.items && positions.items.length % 3 === 1
        })}>
          {positions.items.map((item, i) => (
            <Card
              key={i}
              tagName="article"
              className="Card--bigOnMobile Careers__position"
              iconSrc={item.icon.publicURL || item.icon}
              title={item.name}
              variants={["centerMobile"]}
              textStatic={(
                <>
                  {item.rate &&
                    <div className="Careers__positions__rate">
                      {item.rate}
                    </div>
                  }
                </>
              )}
              text={(
                <>
                  <br />
                  <Button
                    className="Button--full"
                    href={item.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {item.label}
                  </Button>
                </>
              )}
            />
          ))}
        </ul>

        <div className="Careers__container-text Careers__container-text--3">
          <ReactMarkdown children={positions.text} rehypePlugins={[rehypeRaw]} />
          <br />
          <Button href={recruitmentUrl} target="_blank">APPLY</Button>
        </div>
      </div>
    </section> */}
  </div>
);

const CareersPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout variants={['backgrounded']} meta={frontmatter.meta}>
      <CareersPageTemplate {...frontmatter} />
    </Layout>
  );
};

CareersPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CareersPage;

export const careersPageQuery = graphql`
  query CareersPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "careers-page" } }) {
      frontmatter {
        meta {
          title
          description
          image {
            publicURL
            name
          }
        }

        recruitmentUrl

        hero {
          title
          text
          ctaLabel
        }

        photo {
          alt
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
        }

        textPhoto

        ctaNewsletter {
          title
          btnText
        }

        perks {
          title
          text
          items {
            name
            text
            wideSize
            icon {
              publicURL
              name
            }
          }
        }

        process {
          title
          subtitle
          text
        }

        cta {
          title
          btnText
        }
      }
    }
  }
`;
